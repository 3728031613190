import XLSX from 'xlsx';
import ultra_export_json_to_excel from '@ultra/share/utils/exportToExcel.js';

// 读取excel
export function file2Xce (file: any) {
	return new Promise(function (resolve, reject) {
		const reader = new FileReader();
		reader.onload = function (e: any) {
			const data = e.target.result;
			const workbook = XLSX.read(data, {
				type: 'binary'
			});
			// 取第一张表
			const wsname = workbook.SheetNames[0];
			// 生成json表格内容
			const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]);
			resolve(ws);
		};
		reader.readAsBinaryString(file);
	});
}

export function getValue (path: string, obj: any) {
	if (!obj) return null;
	try {
		const _path = path.split('.');
		return _path.reduce((prev, next) => {
			return prev[next];
		}, obj);
	} catch (err) {
		console.log(path);
		console.log(obj);
	}
}

export function setValue (obj: any, key: string, value: any) {
	let str = '';
	key.split('.').forEach(key => {
		str += '["' + key + '"]';
	});
	return eval('obj' + str + '=value'); // eslint-disable-line
}

const wordsStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
export function getMerger (i: number) {
	if (i < 26) {
		return wordsStr[i];
	} else {
		let l = wordsStr[parseInt(String(i / 26)) - 1];
		let r = wordsStr[(i % 26)];
		return l + r;
	}
}
/**
 * 导出表格
 * @param {Array} tHeader 表头
 * @param {Array} filterVal 表头对应字段
 * @param {Array} data 数据
 * @param {String} name 导出名称
 */
 export function exportExcel (tHeader: string[], filterVal: string[], data: any[], name: string) {
	// 导出表格的表头设置
	require.ensure([], () => {
		const table = formatJson(filterVal, data);
		ultra_export_json_to_excel({
			sheets: [
				{ headers: [tHeader], data: table }
			],
			filename: name
		});
	});
}

export function formatJson (filterVal: string[], jsonData: any[]) {
	return jsonData.map(v => filterVal.map(j => getValue(j, v)));
}

// 截取富文本内容
export function ellipsisHtml (html: string, size = 10) {
	let $div = document.createElement('div');
	html = escape2Html(html);
	$div.innerHTML = html;
	let $text = $div.innerText;
	let e = '';
	if ($text.length > size) {
		e = '...';
	}
	$text = $text.substr(0, size);
	$text += e;
	return $text;
}

// 反转义html
export function escape2Html (str: string) {
	let arrEntities: any = { lt: '<', gt: '>', nbsp: ' ', amp: '&', quot: '"' };
	return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) {
		return arrEntities[t];
	});
}

// 获取文件后缀
export function fnGetExtension (file: any) {
	let fileName = file.name && file.name.replace(/\s+/g, '') || file.replace(/\s+/g, '');
	let patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
	let fileExtension = (fileName).match(patternFileExtension);
	return fileExtension;
}

// 获取包的xmlJson信息
export function getXmlJson (data = {}) {
  const xmlJson = JSON.parse(JSON.stringify(data));
  delete xmlJson.icon;
  return JSON.stringify(xmlJson);
}

// 字符串转文件
export function dataURLtoFile (dataurl: string, filename: string) {
	let arr: any = dataurl.split(',');
	let mime = arr[0].match(/:(.*?);/)[1];
	let bstr = atob(arr[1]);
	let n = bstr.length;
	let u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename, {
		type: mime
	});
}
