/* eslint-disable */
import { saveAs } from 'file-saver'
import XLSX from 'xlsx'

function generateArray(table) {
  var out = [];
  var rows = table.querySelectorAll('tr');
  var ranges = [];
  for (var R = 0; R < rows.length; ++R) {
    var outRow = [];
    var row = rows[R];
    var columns = row.querySelectorAll('td');
    for (var C = 0; C < columns.length; ++C) {
      var cell = columns[C];
      var colspan = cell.getAttribute('colspan');
      var rowspan = cell.getAttribute('rowspan');
      var cellValue = cell.innerText;
      if (cellValue !== "" && cellValue == +cellValue) cellValue = +cellValue;

      //Skip ranges
      ranges.forEach(function (range) {
        if (R >= range.s.r && R <= range.e.r && outRow.length >= range.s.c && outRow.length <= range.e.c) {
          for (var i = 0; i <= range.e.c - range.s.c; ++i) outRow.push(null);
        }
      });

      //Handle Row Span
      if (rowspan || colspan) {
        rowspan = rowspan || 1;
        colspan = colspan || 1;
        ranges.push({ s: { r: R, c: outRow.length }, e: { r: R + rowspan - 1, c: outRow.length + colspan - 1 } });
      }
      ;

      //Handle Value
      outRow.push(cellValue !== "" ? cellValue : null);

      //Handle Colspan
      if (colspan) for (var k = 0; k < colspan - 1; ++k) outRow.push(null);
    }
    out.push(outRow);
  }
  return [out, ranges];
};

function datenum(v, date1904) {
  if (date1904) v += 1462;
  var epoch = Date.parse(v);
  return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
}

function sheet_from_array_of_arrays(data, opts) {
  var ws = {};
  var range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };
  for (var R = 0; R != data.length; ++R) {
    for (var C = 0; C != data[R].length; ++C) {
      if (range.s.r > R) range.s.r = R;
      if (range.s.c > C) range.s.c = C;
      if (range.e.r < R) range.e.r = R;
      if (range.e.c < C) range.e.c = C;
      var cell = { v: data[R][C] };
      if (cell.v == null) continue;
      var cell_ref = XLSX.utils.encode_cell({ c: C, r: R });

      if (typeof cell.v === 'number') cell.t = 'n';
      else if (typeof cell.v === 'boolean') cell.t = 'b';
      else if (cell.v instanceof Date) {
        cell.t = 'n';
        cell.z = XLSX.SSF._table[14];
        cell.v = datenum(cell.v);
      }
      else cell.t = 's';

      ws[cell_ref] = cell;
    }
  }
  if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);
  return ws;
}

function Workbook() {
  if (!(this instanceof Workbook)) return new Workbook();
  this.SheetNames = [];
  this.Sheets = {};
}

function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}

export function export_table_to_excel(id) {
  var theTable = document.getElementById(id);
  console.log('a')
  var oo = generateArray(theTable);
  var ranges = oo[1];

  /* original data */
  var data = oo[0];
  var ws_name = "SheetJS";
  console.log(data);

  var wb = new Workbook(), ws = sheet_from_array_of_arrays(data);

  /* add ranges to worksheet */
  // ws['!cols'] = ['apple', 'banan'];
  ws['!merges'] = ranges;

  /* add worksheet to workbook */
  wb.SheetNames.push(ws_name);
  wb.Sheets[ws_name] = ws;

  var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: false, type: 'binary' });

  saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), "test.xlsx")
}

function formatJson(jsonData) {
  console.log(jsonData)
}

/**
 * 导出excel支持多sheet，和合并单元格，自带自动列宽和居中显示
 * @param {*} param0 
 */
export default function ultra_export_json_to_excel({
  sheets = [],
  filename,
  autoWidth = true,
  bookType = 'xlsx'
}) {
  if(!sheets.length) {
    throw Error('there is no sheet') 
  }
  /**
   * sheets = [
   *   {
         headers,
         data:[],
         merges: ['A1:A2','B1:C1'],
         name: ''
   *   }
   * ]
   */
  // 0. 新建工作簿
  const wb = new Workbook(); 
  
  // 1. 处理数据
  for(let i = 0; i<sheets.length; i++) {
    const { headers = [], data = [], merges = [], name } = sheets[i]
    // a. 给数据拼上表头
    let copyData = [...data];
    if(headers.length) {
      for (let i = headers.length - 1; i > -1; i--) {
        copyData.unshift(headers[i]); // 在拼外层的
      }
    }
    const ws_name = name || `SheetJS_${i}`
    const ws = sheet_from_array_of_arrays(copyData)
    // console.log(copyData);

    // b. 合并表头
    if (merges.length > 0) {
      if (!ws['!merges']) ws['!merges'] = []
      merges.forEach(item => {
        ws['!merges'].push(XLSX.utils.decode_range(item))
      })
    }

    // c. 自动列宽处理
    if (autoWidth) {
      /* 设置worksheet每列的最大宽度*/
      const colWidth = copyData.map(row => row.map(val => {
        /* 先判断是否为null/undefined*/
        if (val == null) {
          return {
            'wch': 10
          }
        }
        /* 再判断是否为中文*/
        else if (val.toString().charCodeAt(0) > 255) {
          return {
            'wch': val.toString().length * 2
          }
        } else {
          return {
            'wch': val.toString().length * 1.5
          }
        }
      }))
      /* 以第一行为初始值*/
      const result = colWidth[0]
      for (let i = 1; i < colWidth.length; i++) {
        for (let j = 0; j < colWidth[i].length; j++) {
          if (result[j]['wch'] < colWidth[i][j]['wch']) {
            result[j]['wch'] = colWidth[i][j]['wch']
          }
        }
      }
      ws['!cols'] = result
    }

    // d. 设置单元格样式
    const borderAll = {
      top: {style: 'thin'},
      bottom: {style: 'thin'},
      left: {style: 'thin'},
      right: {style: 'thin'}
    }
    // 给所有单元格加上边框，内容居中，字体，字号，标题表头特殊格式部分后面替换
    for (let i in ws) {
      if (i == '!ref' || i == '!merges' || i == '!cols' || i == '!rows') { } else {
        ws[i + ''].s = {
          border: borderAll,
          alignment: {horizontal: 'center',vertical: 'center'},
          font: {name: '微软雅黑',sz: 13}
        }
      }
    }
    
    // 2. 添加sheet
    wb.SheetNames.push(ws_name)
    wb.Sheets[ws_name] = ws
  }

  // 3. 导出excel
  const wbout = XLSX.write(wb, {
    bookType: bookType,
    bookSST: false,
    type: 'binary'
  })
  saveAs(new Blob([s2ab(wbout)], {
    type: 'application/octet-stream'
  }), `${filename}.${bookType}`)
}